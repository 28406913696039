body {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}

.sidebar-scrollbar {
  overflow: auto;
}

.sidebar-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.hidden {
  display: none;
}

.scrollbar-hidden {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.page-root {
}

@keyframes breathing {
  0% {
    opacity: 0.33;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.33;
  }
}

.animation-breathing {
  animation-name: breathing;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.customScrollbar-X {
  scrollbar-color: #888 rgba(0, 0, 0, 0);
}
.customScrollbar-Y {
  scrollbar-color: #888 rgba(0, 0, 0, 0);
}

.widget {
  border: none;
  transition: 0.5s all;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  background-color: rgba(30, 30, 30, 0.5);
  border-radius: 20px;
}

.widget:hover {
  border: 1px solid #5b84b4;
  transition: 0.5s all;
  box-shadow: #436c9a 0px 0px 30px 0px, inset 0px 0px 20px #2c4b6e;
}

.appGradient {
  background: linear-gradient(180deg, #1e374f 0%, #0b1826 100%);
}

.page-content {
  box-sizing: border-box;
  color: white;
  height: 100%;
  width: 100%;
  padding: 20px;
}

/* .dark * button {
  background-color: red !important;
  color: white !important;
}

.dark * button:hover {
  background-color: #26ff00 !important;
  color: white !important;
} */

.screenTooSmall {
  display: none !important;
}

@media (max-width: 1240px) {
  .screenTooSmall {
    display: flex !important;
  }
}
.leaflet-popup-content {
  width: auto !important;
}

.html-content p {
  margin-bottom: 20px;
}

.html-content h1 {
  margin-bottom: 10px;
  font-size: 2em;
}

.html-content h2 {
  margin-bottom: 10px;
  font-size: 1.75em;
}

.html-content h3 {
  margin-bottom: 5px;
  font-size: 1.5em;
}

.html-content h4 {
  margin-bottom: 5px;
  font-size: 1.25em;
}

.html-content li, .html-content ul {
  margin-bottom: 5px;
  list-style-type: none;
}

.html-content a {
  color: white
}