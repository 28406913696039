:root {
  /* non-changing colors */
  --warningRed: #ff0000;
}

/* affect all elements */
.mobile p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* base mobile window */
.mobile {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  /* max-height: 100vh; */
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--fontColor) !important;
  box-sizing: border-box;
  /* border: 1px solid lime; */
  /* overflow-x: hidden; */
  /* overflow: auto; */
}

.mobile .content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* affect all elements */
.mobile * {
  box-sizing: border-box;
}

/* style types, such as border-bottom or title */

.mobile .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile .bg-primary {
  background: var(--primary) !important;
}

.mobile .bg-primaryShadow {
  background: var(--primaryShadow) !important;
}

.mobile .bg-primaryHighlight {
  background: var(--primaryHighlight) !important;
}

.mobile .bg-base {
  background: var(--base) !important;
}

.mobile .bg-background {
  background: var(--background) !important;
}

.mobile .DEBUG {
  background-color: red !important;
  border: 1px solid white !important
}

.mobile .DEBUG1 {
  border: 1px solid rgba(255,0,0,.8) !important;
}

.mobile .DEBUG2 {
  border: 1px solid rgba(255,255,0,.8) !important;
}

.mobile .DEBUG3 {
  border: 1px solid rgba(0,255,0,.8) !important;
}

.mobile .DEBUG4 {
  border: 1px solid rgba(0,255,255,.8) !important;
}

.mobile .DEBUG5 {
  border: 1px solid rgba(0,0,255,.8) !important;
}

.mobile .DEBUG6 {
  border: 1px solid rgba(255,0,255,.8) !important;
}


/* title style */
.mobile .title {
  font-size: 18px;
  font-weight: bold;
}

/* custom classes */
.mobile .button {
  padding: 20px;
  border-radius: 10px;
  /* margin: 10px; */
}

.mobile .overflow {
  overflow: auto;
}

.mobile .round {
  border-radius: 50% !important;
  height: 30px;
  width: 30px;
  align-items: center !important;
  justify-content: center !important;

}

.mobile .flex {
  display: flex;
}

.mobile .column {
  display: flex;
  flex-direction: column;
}

.mobile .row {
  display: flex;
  flex-direction: row;
}

.mobile .center {
  align-items: center !important;
  justify-content: center !important;
  
}

.mobile .justify-between {
  justify-content: space-between;
}

.mobile .h100 {
  height: 100% !important;
}

.mobile .w100 {
  width: 100% !important;
}

.mobile .gap-10 {
  gap: 10px !important;
}

.mobile .gap-20 {
  gap: 20px;
}

.mobile .left {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.mobile .right {
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

/* header + menu container */
.mobile .menu {
  display: flex;
  flex-direction: column;
  z-index: 999;
}

/* header with logo and user action button */
.mobile .header {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* header logo */
.mobile .logo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-height: 80px;
}

.mobile .logo img {
  height: auto;
  max-height: 80px;
  max-width: 100%;
}

/* user action menu */
.mobile .expandedMenu {
  height: calc(100vh - 100px);
  position: fixed;
  top: 100px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

/* list item base style*/
.mobile .listItem {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100vw;
}

.mobile .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.mobile .popup-base {
  background: var(--background);
  border-radius: 10px;
  padding: 20px;
  width: 90vw;
  height: 90vh;
  /* height: 85vh;
  margin-bottom: 100px; */
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.mobile .popup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  font-weight: bold;
}

.mobile .popup-header button {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile .popup-content {
  flex: 1;
  overflow-y: auto;
  padding-top: 10px;
}

.mobile .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  align-content: flex-start;
  justify-content: flex-start;
}

.mobile .mapContainer {
  height: 100%;
  width: 100%;
  padding: 10px;
}